
import Redactor from '@/plugins/redactor/redactor.usm.min';
import handle from '@/plugins/handle.redactor'; // DO NOT REMOVE

export default {
  name: 'RedactorInput',
  props: {
    model_type: {
      type: String,
      required: true,
    },
    model_uuid: {
      type: String,
      required: true,
    },
    handle_type: {
      type: String,
      required: false,
      default: 'note',
    },
    value: {
      type: String,
      required: false,
    },
    setRef: {
      type: String,
      required: false,
      default: function () {
        return 'editor-' + this.model_type + '-' + this.model_uuid + '-' + this.handle_type;
      },
    },
    hasFocus: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      url: '',
      redactor: null,
      editorElement: null,
    };
  },

  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: '/handle.min.css',
        },
      ],
    };
  },

  mounted() {
    this.buildRedactor();
    this.editorElement = this.$refs[this.setRef];
    this.buildRedactor();
  },

  methods: {
    clearContents() {
      Redactor(this.editorElement, 'source.setCode', '<p></p>');
    },
    buildRedactor() {
      switch (this.handle_type) {
        case 'note':
          this.url = '/note/' + this.model_type + '/' + this.model_uuid + '/mentions';
          break;
        case 'conversation':
          this.url = '/conversation/' + this.model_uuid + '/mentions';
          break;
      }

      Redactor(this.editorElement, 'destroy');
      Redactor(this.editorElement, {
        focus: this.hasFocus,
        air: true,
        shortcodes: false,
        plugins: ['handle'],
        buttons: ['bold', 'italic', 'deleted', 'lists', 'link'],
        handle: this.$nuxt.$api.host + this.url,
        callbacks: {
          blur: (e) => {
            this.$emit('blur');
          },
          changed: (e) => {
            let content = Redactor(this.editorElement, 'source.getCode');
            this.$emit('input', content);
          },
        },
      });

      if (this.value) {
        Redactor(this.editorElement, 'source.setCode', this.value);
      }
    },
  },
  watch: {
    handle_type(new_val) {
      this.buildRedactor();
    },
    model_type(new_val) {
      this.buildRedactor();
    },
    model_uuid(new_val) {
      this.buildRedactor();
    },
  },
};
