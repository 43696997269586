export default function ({ store, redirect, app }) {
  if (!store.getters['system/isIPAllowed']) {
    return redirect('/ip-not-allowed');
  }

  if (store.getters['user/isNotSeniorEmployee']) {
    setTimeout(() => {
      window.$nuxt.$emit('notification', 'Members are not allowed to access this area. Please contact your administrator.', 'warning', true);
    }, 500);
    return redirect('/');
  }
}
