
export default {
  name: 'Toggle',
  props: {
    label: {
      type: Boolean,
      default: false,
    },
    labelRight: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, null],
      required: false,
      default: false,
    },
    disbaledMessage: {
      type: String,
      default: 'Disabled',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggleActive: false,
    };
  },
  created() {
    this.toggleActive = this.value;
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.toggleActive = !this.toggleActive;
      }
    },
  },
  watch: {
    toggleActive(new_val) {
      this.$emit('input', new_val);
    },
    value(new_val) {
      this.toggleActive = new_val;
    },
  },
};
