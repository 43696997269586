import paginator from '@/helpers/paginator';

export default {
  //Tasks
  getAllTasksFromFirm: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/task', {}, data),
  getAllTasks: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/task', data),
  getAllPaginatedTasks: async (data = null) => await paginator.grabAllPages($nuxt.$api.host + '/task', {}, data),
  createTask: (data = null) => $nuxt.$axios.post($nuxt.$api.host + '/task/', data),
  getTask: (task_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/task/' + task_uuid, data),
  updateTask: (task_uuid, data = null) => $nuxt.$axios.put($nuxt.$api.host + '/task/' + task_uuid, data),
  deleteTask: (task_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/task/' + task_uuid),
  completeTask: (task_uuid) => $nuxt.$axios.patch($nuxt.$api.host + '/task/' + task_uuid + '/complete'),
  uncompleteTask: (task_uuid, task_status_uuid) =>
    $nuxt.$axios.patch($nuxt.$api.host + '/task/' + task_uuid + '/uncomplete/' + task_status_uuid),
  archiveTask: (task_uuid) => $nuxt.$axios.patch($nuxt.$api.host + '/task/' + task_uuid + '/archive'),
  unarchiveTask: (task_uuid, task_status_uuid) =>
    $nuxt.$axios.patch($nuxt.$api.host + '/task/' + task_uuid + '/unarchive/' + task_status_uuid),

  //Task Statuses
  getAllTaskStatusesFromFirm: async (data = null) =>
    await paginator.grabAllPages($nuxt.$api.host + '/task-status', {}, data),
  createATaskStatus: (data = null) => $nuxt.$axios.post($nuxt.$api.host + '/task-status/', data),
  getATaskStatus: (task_status_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/task-status/' + task_status_uuid),
  updateATaskStatus: (task_status_uuid, data = null) =>
    $nuxt.$axios.put($nuxt.$api.host + '/task-status/' + task_status_uuid, data),
  deleteATaskStatus: (task_status_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/task-status/' + task_status_uuid),
};
