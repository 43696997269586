import { render, staticRenderFns } from "./client.vue?vue&type=template&id=5edd3f27"
import script from "./client.vue?vue&type=script&lang=js"
export * from "./client.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNotificationEventListener: require('/vercel/path0/components/AppNotificationEventListener.vue').default,BrandingColoursCSS: require('/vercel/path0/components/BrandingColoursCSS.vue').default,NavigationAccountSwitcher: require('/vercel/path0/components/navigation/AccountSwitcher.vue').default,Navigation: require('/vercel/path0/components/Navigation.vue').default,Avatar: require('/vercel/path0/components/Avatar.vue').default,Navigation: require('/vercel/path0/components/Navigation.vue').default,SessionExpiry: require('/vercel/path0/components/SessionExpiry.vue').default,NotificationsNotificationIcon: require('/vercel/path0/components/notifications/notificationIcon.vue').default,RegText: require('/vercel/path0/components/RegText.vue').default,Notification: require('/vercel/path0/components/Notification.vue').default,NotificationsNotificationModal: require('/vercel/path0/components/notifications/notificationModal.vue').default,FileUploader: require('/vercel/path0/components/FileUploader.vue').default,ConfirmDialog: require('/vercel/path0/components/ConfirmDialog.vue').default,ModalsConversationModal: require('/vercel/path0/components/modals/conversation-modal.vue').default})
