
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'App',
  data() {
    return {
      menuOpen: false,
      firm: {},
    };
  },

  computed: {
    ...mapState('user', ['user', 'currentLogin']),
    ...mapState(['appLoading']),
    ...mapGetters('system', ['isInProduction']),
  },

  created() {
    this.getFirmDetails();
  },

  methods: {
    getFirmDetails() {
      if (this.user.current_account.firm.logo) {
        this.firm.logo = this.user.current_account.firm.logo;
        this.firm.name = this.user.current_account.firm.name;
      }
    },

    openConversationModal() {
      this.$refs.conversationModal.open();
    },

    openMenu() {
      this.menuOpen = true;
      let gsap = this.$gsap;

      this.$nextTick(() => {
        let wrapper = this.$refs.mobileMenuWrapper;
        let menu = this.$refs.mobileMenu;

        this.tl = gsap.timeline({
          onComplete: () => {},
        });

        this.tl.set(menu, { x: -400 }).to(wrapper, { opacity: 1, duration: 0.25 }).to(menu, { x: 0, duration: 0.5 });
      });
    },

    closeMenu() {
      let gsap = this.$gsap;

      let wrapper = this.$refs.mobileMenuWrapper;
      let menu = this.$refs.mobileMenu;

      this.tl = gsap.timeline({
        onComplete: () => {
          this.menuOpen = false;
        },
      });

      this.tl.to(menu, { x: -400, duration: 0.5 }).to(wrapper, { opacity: 0, duration: 0.25 });
    },
  },
  watch: {
    $route(to, from) {
      this.closeMenu();
    },
  },
};
